import React from "react";
import { useState, useEffect } from "react";

export default function Countdown(props) {
  const { startingMinutes = 0, startingSeconds = 10 } = props;
  const [mins, setMinutes] = useState(startingMinutes);
  const [secs, setSeconds] = useState(startingSeconds);
  useEffect(() => {
    let sampleInterval = setInterval(() => {
      if (secs > 0) {
        setSeconds(secs - 1);
      }
      if (secs === 0) {
        if (mins === 0) {
          clearInterval(sampleInterval);
        } else {
          setMinutes(mins - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(sampleInterval);
    };
  });

  return (
    <>
      {(!mins && !secs) ? <h1 class="countdown">Entzündet den Tornado!</h1> : (
        <h1 class="countdown">
          {mins}:{secs < 10 ? `0${secs}` : secs}
        </h1>
      )}
    </>
  );
}
import React, {Component, useState, useRef, useEffect} from 'react'
import Countdown from './Countdown'
import {v4 as uuidv4} from 'uuid'

const LOCAL_STORAGE_KEY = 'todoApp.todos'

function App() {
  const [name, setName] = useState('')
  const [distance, setDistance] = useState('');
  const [amount, setAmount] = useState('');
  const [duration, setDuration] = useState('')
  const [impress, setImpress] = useState('')
  const [countdownRunning, setCountdownRunning] = useState(false)

  const handleDistanceChange = (event) => {
    setDistance(event.target.value);
  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const handleDurationChange = (event) => {
    setDuration(event.target.value);
  };

  const handleImpressChange = (event) => {
    setImpress(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setCountdownRunning(!countdownRunning);
  }

  const Dropdown = ({ label, value, options, onChange }) => {
    return (
      <label>
        {label}
        <select required value={value} onChange={onChange}>
          {options.map((option) => (
            <option value={option.value}>{option.label}</option>
          ))}
        </select>
      </label>
    );
  };

  function getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1) ) + min;
  }

  function predictTornado() {
    if (name === "Tornado Gang") {
      return 10;
    }
    
    var prediction;

    switch(distance) {
      case "hand":
        prediction = getRndInteger(5, 30)
        break;
      case "griffbereit":
        prediction = getRndInteger(25, 90)
        break;
      case "naehe":
        prediction = getRndInteger(40, 180)
        break;
      case "keller":
        prediction = getRndInteger(180, 600)
        break;
      default:
        prediction = getRndInteger(10, 600)
    }
    
    return {
      minutes: Math.floor(prediction / 60),
      seconds: (prediction % 60),
    };
  }

  if (!countdownRunning){
  return (
    <>
      <img src={"./logo.png"} alt="logo" width={100} height={100} class="title"/>
      <h1 class="title">einsturmziehtauf.de</h1>
      <form onSubmit={handleSubmit}>
        <label>Wie heißt du?
          <input
            required
            type="text" 
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>
      
      <Dropdown
        label="Wie weit ist das nächste Bier entfernt?"
        options={[
          { label: 'Auswählen', value: '' },
          { label: 'In meiner Hand', value: 'hand' },
          { label: 'Griffbereit', value: 'griffbereit' },
          { label: 'In der Nähe', value: 'naehe' },
          { label: 'Im Keller', value: 'keller' },
        ]}
        value={distance}
        onChange={handleDistanceChange}
        />

      <Dropdown
        label="Wie viele hattest du schon?"
        options={[
          { label: 'Auswählen', value: '' },
          { label: '0', value: '0' },
          { label: '1', value: '1' },
          { label: '2', value: '2' },
          { label: '3', value: '3' },
          { label: '4', value: '4' },
          { label: '5', value: '5' },
          { label: '6', value: '6' },
          { label: '7', value: '7' },
          { label: '8', value: '8' },
          { label: '9', value: '9' },
          { label: '10', value: '10' },
          { label: '>10', value: '>10' },
        ]}
        value={amount}
        onChange={handleAmountChange}
        />

      <Dropdown
        label="Wie lange bist du schon am Trinken?"
        options={[
          { label: 'Auswählen', value: '' },
          { label: 'Weniger als 1 Stunde', value: '1' },
          { label: 'Weniger als 2 Stunden', value: '2' },
          { label: 'Weniger als 4 Stunden', value: '4' },
          { label: 'Weniger als 6 Stunden', value: '6' },
          { label: 'Weniger als 8 Stunden', value: '8' },
          { label: 'Weniger als 10 Stunden', value: '10' },
          { label: 'Mehr als 10 Stunden', value: '11' },
        ]}
        value={duration}
        onChange={handleDurationChange}
        />

      <Dropdown
        label="Musst du jemanden beeindrucken?"
        options={[
          { label: 'Auswählen', value: '' },
          { label: 'Ja', value: true },
          { label: 'Nein', value: false },
        ]}
        value={impress}
        onChange={handleImpressChange}
        />

        <input type="submit" value="Okaaayyyy, lets go!"/>
      </form>
      
      <countdownRunning Countdown ingMinutes={0} startingSeconds={15}/>
      
      {/*
        <p>Wie heißt du? {name}</p>
        <p>Wie weit ist das nächste Bier entfernt? {distance}</p>
        <p>Wie viele hattest du schon? {amount}</p>
        <p>Wie lange bist du schon am Trinken? {duration}</p>
        <p>Musst du jemanden beeindrucken? {impress}</p>
        <p>Countdown {countdownRunning.toString()}</p>
      */}
      <p class="subtext">
          Manu Neuer (Manuel Neuer): Orangensaft (frag nicht was für Saft) - Es ist Orangensaft ((immer))
      </p>
    </>
    )
  }
  else{
    var prediction = predictTornado()
    return(
      <>
        <img src={"./logo.png"} alt="logo" width={100} height={100} class="title"/>
        <h1 class="title">einsturmziehtauf.de</h1>

        <Countdown startingMinutes={prediction.minutes} startingSeconds={prediction.seconds}/>

        <form onSubmit={handleSubmit}>
          <input type="submit" value="Gezündet!"/>
        </form>

        <p>
          Manu Neuer (Manuel Neuer): Orangensaft (frag nicht was für Saft) - Es ist Orangensaft ((immer))
        </p>
      </>
    )
  };
}

export default App;
